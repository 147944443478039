
export const toShortDateString = (date: Date, timeZone: string = 'UTC') => date.toLocaleString('default', { timeZone, month: 'short', year: 'numeric', day: 'numeric', weekday: 'short' });
export const toNumericDateString = (date: Date, timeZone: string = 'UTC') => date.toLocaleString('default', { timeZone, month: 'numeric', year: 'numeric', day: 'numeric' });
export const toHoursMinutesString = (date: Date, timeZone: string = 'UTC') => date.toLocaleString('default', { timeZone, hour: '2-digit', minute: '2-digit' });

export const timestampToNumericDate = (timestamp: string, timeZone: string = 'UTC') => toNumericDateString(new Date(timestamp), timeZone);
export const timestampToShortDate = (timestamp: string, timeZone: string = 'UTC') => toShortDateString(new Date(timestamp), timeZone);
export const timestampToHoursMinutes = (timestamp: string, timeZone: string = 'UTC') => toHoursMinutesString(new Date(timestamp), timeZone);

export const timestampToLongDate = (timestamp: string, timeZone: string = 'UTC') => {
  return new Date(timestamp)
    .toLocaleString('default', { timeZone, month: 'short', year: 'numeric', day: 'numeric', weekday: 'short', hour: '2-digit', minute: '2-digit' });
};

export const isDateWithinDateRange = (date: Date, start: Date, end: Date) => {
  return start <= date && date <= end;
};

export const daysInYear = (year: number) => {
  return ((year % 4 === 0 && year % 100 > 0) || year % 400 === 0) ? 366 : 365;
}

// Month in JavaScript is 0-indexed (January is 0, February is 1, etc),
// but by using 0 as the day it will give us the last day of the prior
// month. So passing in 1 as the month number will return the last day
// of January, not February
export const daysInMonth = (month: number, year: number) => {
  return new Date(year, month, 0).getDate();
}

// Test cases:
// 1/1/11 -> 1/2/11 = 2 days
// 1/1/11 -> 12/31/11 = 365 days
export const numberOfDaysBetween = (first: Date, second: Date, inclusive: boolean = true) => {
  const daysBetween = Math.round((second.valueOf() - first.valueOf()) / (1000 * 60 * 60 * 24));

  // Add one to be inclusive of both start and end date
  return daysBetween + (inclusive ? 1 : 0);
}

// TODO: pretty sure this returns the wrong date due to timezones
export const getThisYearStart = () => {
  const date = new Date();
  date.setUTCMonth(0, 1);
  date.setUTCHours(0, 0, 0, 0);
  return date;
}

export const getThisYearEnd = () => {
  const date = new Date();
  date.setUTCMonth(11, 31);
  date.setUTCHours(23, 59, 59, 999);
  return date;
}

export const getLastYearStart = () => {
  const thisYearStart = getThisYearStart();
  thisYearStart.setFullYear(thisYearStart.getFullYear() - 1);
  return thisYearStart;
}

export const getLastYearEnd = () => {
  const thisYearEnd = getThisYearEnd();
  thisYearEnd.setFullYear(thisYearEnd.getFullYear() - 1);
  return thisYearEnd;
}

// Mantine always returns local dates from date pickers.
// converts midnight local time to midnight UTC
export const dateToMidnightUTC = (date: Date) => {
  const updatedDate = new Date(date.toISOString().slice(0, -1));
  updatedDate.setUTCHours(0,0,0,0);
  return updatedDate;
}

// the end date in a date range should be inclusive of all hours for that day
export const dateToLastSecondOfDayUTC = (date: Date) => {
  const updatedDate = new Date(date.toISOString().slice(0, -1));
  updatedDate.setUTCHours(23,59,59);
  return updatedDate;
}

export const nMinutesAgo = (minutes: number = 5) => {
  const nowEpoch = Date.now();
  const minutesInMs = minutes * 60 * 1000;
  return new Date(nowEpoch - minutesInMs);
}

export const getYearsBetweenDates = (startDate: Date, endDate: Date) => {
  // get all of the years between the two dates, inclusive
  const years: number[] = [];
  for (let year = startDate.getFullYear(); year <= endDate.getFullYear(); year++) {
    if (!years.includes(year)) {
      years.push(year);
    }
  }
  return years;
};